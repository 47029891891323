import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { getAccessLevels } from "../actions/index";
import { connect } from "react-redux";
import { compose } from "recompose";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { apiRequest,atLastPageScrollToTop, byName, formatDate } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import { showMessage } from "../actions/index";

function desc(a, b, orderBy) {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "image", numeric: false, disablePadding: false, label: "IMAGE" },
  { id: "UserId", disablePadding: false, label: "USER ID" },
  { id: "FullName", numeric: false, disablePadding: false, label: "FULL NAME" },
  { id: "Created", numeric: false, disablePadding: false, label: "CREATED" }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell /*padding="checkbox"*/>
            <Checkbox
              style={{ padding: 0 }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              color="primary"
              onChange={onSelectAllClick}
            />{" "}
            Select all
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                    disabled={row.id == "image"}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const toolbarStyles = theme => ({
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: "#4b2e83"
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 60%"
  },
  actions: {
    color: "#4b2e83",
    width: "100%"
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        <h4 style={{ color: "#4b2e83" }}>Map Devices</h4>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Typography color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  table: {
    minWidth: 50
  },
  tableWrapper: {
    overflowX: "auto"
  },
  button1: {
    color: "#4b2e83",
    height: "40px",
    width: "90px",
    padding: "0px !important",
    marginRight: "18px"
  },
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  textField: { width: "100%", margin: "0px !important" }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "UserId",
      selected: [],
      data: props.users,
      page: 0,
      rowsPerPage: 5,
      access: "",
      accessLevels: [],
      deptId: props.deptId,
      hideControls: true,
      openAlert: false
    };
  }

  componentWillMount() {
    this.props.getAccessLevel();
  }

  componentWillReceiveProps(newProps) {
    this.setState(
      {
        data: newProps.users,
        deptId: newProps.deptId
      },
      () => {
        if (this.state.hideControls) {
          let levels = newProps.accessLevels;
          this.setState({
            accessLevels: levels.sort(byName)
          });
        }
      }
    );
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, data) => {
    const { selected } = this.state;
    const selectedIndex = selected.findIndex(x => x.UserId == id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, ()=>{
      atLastPageScrollToTop(this.state.data.length, this.state.rowsPerPage, page);
    });
  };

  handleChangeRowsPerPage = event => {
    if(event.target.value * this.state.page > this.state.data.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => {
    return this.state.selected.findIndex(x => x.UserId ==id) !== -1 };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  checkAccess() {
    apiRequest(
      `/accesscontrol?resourceType=collection&resourceId=${this.state.deptId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] <= 300) {
          this.setState({
            hideControls: true
          });
        } else {
          this.setState({
            hideControls: false
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  handleAssign = () => {
    this.setState({
      openAlert: false
    });
    this.props.selectedUsers(this.state.selected, this.state.access, 0);
    this.setState({ selected: [] });
  };

  render() {
    const { numSelected } = this.props;
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleAssign}
          action={"Assign"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Paper className={classes.root} style={{ padding: 10, height: "100%" }}>
          <div className="border-bottom">
            <h4
              style={{
                color: "#4b2e83",
                width: "100%",
                paddingLeft: 14
              }}
            >
              Assign Users to Permission Groups
            </h4>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.UserId);
                    return (
                      <TableRow
                        hover
                        style={{ height: 42 }}
                        onClick={event => this.handleClick(event, n.UserId, n)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.UserId}
                        selected={isSelected}
                      >
                        <TableCell
                          style={{ width: "140px" }}
                          //padding="checkbox"
                        >
                          <Checkbox
                            style={{ padding: 0 }}
                            classes={{
                              root: classes.rootcheckbox,
                              checked: classes.checked
                            }}
                            color="primary"
                            checked={isSelected}
                          />
                        </TableCell>
                        <TableCell style={{ width: 140 }}>
                          <img
                          className="image"
                            src={n.ImageURLSmall}
                            height="27px"
                            width="27px"
                            style={{
                              borderRadius: "50%"
                            }}
                          />
                        </TableCell>
                        <TableCell style={{ width: "160px" }} align="center">
                          {n.UserId}
                        </TableCell>
                        <TableCell>{n.FullName}</TableCell>
                        <TableCell>{n.Created ? formatDate(new Date(n.Created + "Z")) : ""}</TableCell>
                      </TableRow>
                    );
                  })}
                {/*data.length > rowsPerPage && emptyRows > 0 && (
                  <TableRow style={{ height: 42 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )*/}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5,10, 25, 50, 100]}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Grid container spacing={16}>
            <Grid item xs={12} md={5} lg={5}>
              <h6
                className="float-left"
                style={{
                  paddingLeft: "3px",
                  color: "#4b2e83"
                }}
              >
                {this.state.selected.length + " row(s) selected."}
              </h6>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <TextField
                width="100%"
                id="outlined-select"
                select
                required
                label="Access Level"
                margin="dense"
                className={classes.textField}
                value={this.state.access}
                onChange={this.handleChange("access")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                InputProps={{ className: classes.input }}
                InputLabelProps={{
                  className: classes.label
                }}
                variant="outlined"
              >
                {this.state.accessLevels.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Button
                onClick={() => {
                  if (this.state.access == "") {
                    this.props.showMessageBox("Select Access Level");
                    return;
                  }
                  if (this.state.selected.length == 0) {
                    this.props.showMessageBox("Select User");
                    return;
                  }
                  this.setState({
                    openAlert: true
                  });
                }}
                variant="outlined"
                //className={classNames(classes.button1,"float-right","button1")}
                className={classNames("float-right","button1")}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { accessLevels } = state;
  return {
    accessLevels
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAccessLevel: () => getAccessLevels(dispatch),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EnhancedTable);
